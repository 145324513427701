
import defaultMeta from "@/mixins/defaultMeta"

import { mapGetters } from "vuex"
import traineeNavigationMixin from "@/mixins/traineeNavigationMixin"

export default {
    components: {
        ImpersonateHeader: () => import("@/components/admin/ImpersonateHeader"),
        SVGGradient: () => import("@/components/base/design/SVGGradient"),
        ProgressCircular: () => import("@/components/base/ProgressCircular"),
        Footer: () => import("@/components/layout/navigation/Footer"),
        Onboarding: () => import("@/components/onboarding/Onboarding"),
        SearchModal: () => import("@/components/layout/SearchModal"),
        CookieInformation: () =>
            import("@/components/layout/CookieInformation"),
        Login: () => import("@/components/auth/Login"),
        Sidebar: () => import("@/components/layout/navigation/Sidebar"),
        BottomNav: () => import("@/components/layout/navigation/BottomNav"),
        CreateDialog: () => import("@/components/business/CreateDialog"),
        Toolbar: () => import("@/components/layout/navigation/Toolbar"),
        LivestreamBadge: () => import("@/components/layout/LivestreamBadge"),
    },
    mixins: [defaultMeta, traineeNavigationMixin],
    data() {
        return {
            createDialog: false,
        }
    },
    computed: {
        ...mapGetters("meta", ["meta"]),
        ...mapGetters("ui", ["loadingSpinner"]),
        ...mapGetters("chat", { isChatSelected: "isSelected" }),
        ...mapGetters("cookie", ["showCookieConsent"]),
        ...mapGetters("account", ["onboarding"]),
        ...mapGetters("navigation", {
            sidebarActions: "actions",
            sidebarMenuItems: "menuItems",
        }),
        showSidebar() {
            return (
                this.$can.school() || this.$can.region() || this.$can.business()
            )
        },
        mainStyle() {
            /* Make main at least 100vh being white */
            const { footer, insetFooter, bottom } = this.$vuetify.application
            return {
                "padding-top": !this.meta.toolbar?.persistent
                    ? "0px"
                    : undefined,
                "min-height": `calc(100vh + ${
                    footer + insetFooter + bottom
                }px)`,
            }
        },
        isChatFullscreen() {
            return this.isChatSelected && this.$breakpoint.mdAndDown
        },
        showToolbar() {
            const hidden = this.meta.toolbar?.hidden
            let isHidden
            if (hidden) {
                if (typeof hidden === "string") {
                    isHidden = this.$breakpoint[hidden]
                } else {
                    isHidden = !!hidden
                }
            }
            return (
                !this.$can.business() &&
                !this.$can.school() &&
                !this.$can.region() &&
                !this.isChatFullscreen &&
                !this.isRegionPage &&
                !isHidden
            )
        },
        showBottomNav() {
            return (
                this.$breakpoint.smAndDown &&
                !this.meta.hideBottomNav &&
                !this.$can.business()
            )
        },
        /* Check to avoid flickering of Oabat logo
        on navigation between alles page & subpages */
        isRegionPage() {
            return this.$route.name.includes("region-slug")
        },
        showFooter() {
            return !this.meta.footer?.hidden && !this.$auth.loggedIn
        },
        /** Avoids fold flicker with SSR & client-only evaluated toolbar */
        mainClasses() {
            return { "fix-flicker": this.$route.fullPath == "/" }
        },
        showLivestreamBadge() {
            const validRoutes = [
                "unternehmen-locationSlug",
                "ausbildung-locationSlug",
                "praktikum-locationSlug",
                "index",
                "betrieb",
                "schule",
                "region",
            ]

            return (
                this.$livestream.isReady &&
                this.$livestream.isUpcoming &&
                validRoutes.includes(this.$route.name)
            )
        },
    },
    methods: {
        onOnboardingFinished() {
            this.$router.push(this.traineeHomeRoute)
        },
    },
}
